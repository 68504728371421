import React from 'react'
import Container from '../Container/Container'
import WeddingTypeCard from '../WeddingTypeCard/WeddingTypeCard'
import './HomeMarketingOne.scss'
import wtc1 from '../../Assets/Home/curvednr.png'
import wtc2 from '../../Assets/Home/curvedsocial.png'
import wtc3 from '../../Assets/Home/curvedwedding.png'

function HomeMarketingOne() {

  return (
    <section className="marketing-one">
        <Container>
            <div className="marketing-one__title">
                <div className='title'> 
                  Spectrum of our 
                  <br/>
                  Event management services
                </div>
            </div>
            <div className="marketing-one__card__wrapper row">
              <div className=" col-12 col-md-4">
                <div className="marketing-one__card one">
                  <WeddingTypeCard image={wtc3} title="Wedding management" caption="The Best Wedding planners in Ahmedabad" link="/luxury-wedding-planning-services"
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="marketing-one__card two">
                  <WeddingTypeCard image={wtc2} title="Social Events management" caption="The Best Social event managers in Ahmedabad" link="/social-event-management-company"/>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="marketing-one__card three">
                  <WeddingTypeCard image={wtc1} title="Corporate events management" caption="The Best Event planners in Ahmedabad" link="/corporate-event-management-company"/>
                </div>
              </div>
            </div>
           
        </Container>
    </section>
  )
}

export default HomeMarketingOne