import image1 from '../Assets/Images/1.jpg'
import image2 from '../Assets/Images/2.jpg'
import image3 from '../Assets/Images/3.jpg'
import image4 from '../Assets/Images/4.jpg'
import image5 from '../Assets/Images/5.jpg'
import image6 from '../Assets/Images/6.jpg'
import image7 from '../Assets/Images/7.jpg'
import image8 from '../Assets/Images/8.jpg'
import image9 from '../Assets/Images/9.jpg'
import image10 from '../Assets/Images/10.jpg'
import image11 from '../Assets/Images/11.jpg'
import image12 from '../Assets/Images/12.jpg'
import image13 from '../Assets/Images/13.jpg'
import image14 from '../Assets/Images/14.jpg'
import image15 from '../Assets/Images/15.jpg'
import image16 from '../Assets/Images/16.jpg'
import image17 from '../Assets/Images/17.jpg'
import image18 from '../Assets/Images/18.jpg'
import image19 from '../Assets/Images/19.jpg'
import image20 from '../Assets/Images/20.jpg'
import image21 from '../Assets/Images/21.jpg'
import image22 from '../Assets/Images/22.jpg'
import image23 from '../Assets/Images/23.jpg'
import image24 from '../Assets/Images/24.jpg'
import image25 from '../Assets/Images/25.jpg'
import image26 from '../Assets/Images/26.jpg'
import image27 from '../Assets/Images/27.jpg'
import image28 from '../Assets/Images/28.jpg'
import image29 from '../Assets/Images/29.jpg'
import image30 from '../Assets/Images/30.jpg'
import image31 from '../Assets/Images/31.jpg'
import image32 from '../Assets/Images/32.jpg'
import image33 from '../Assets/Images/33.jpg'
import image34 from '../Assets/Images/34.jpg'
import image35 from '../Assets/Images/35.jpg'
import image36 from '../Assets/Images/36.jpg'
import image37 from '../Assets/Images/37.jpg'
import image38 from '../Assets/Images/38.jpg'
import image39 from '../Assets/Images/39.jpg'
import image40 from '../Assets/Images/40.jpg'
import image41 from '../Assets/Images/41.jpg'
import image42 from '../Assets/Images/42.jpg'
import image43 from '../Assets/Images/43.jpg'
import image44 from '../Assets/Images/44.jpg'
import image45 from '../Assets/Images/45.jpg'
import image46 from '../Assets/Images/46.jpg'
import image47 from '../Assets/Images/47.jpg'
import image48 from '../Assets/Images/48.jpg'
import image49 from '../Assets/Images/49.jpg'
import image50 from '../Assets/Images/50.jpg'
import image51 from '../Assets/Images/51.jpg'
import image52 from '../Assets/Images/52.jpg'
import image53 from '../Assets/Images/53.jpg'
import image54 from '../Assets/Images/54.jpg'
import image55 from '../Assets/Images/55.jpg'
import image56 from '../Assets/Images/56.jpg'
import image57 from '../Assets/Images/57.jpg'
import image58 from '../Assets/Images/58.jpg'
import image59 from '../Assets/Images/59.jpg'
import image60 from '../Assets/Images/60.jpg'
import image61 from '../Assets/Images/61.jpg'
import image62 from '../Assets/Images/62.jpg'
import image63 from '../Assets/Images/63.jpg'
import image64 from '../Assets/Images/64.jpg'
import image65 from '../Assets/Images/65.jpg'
import image66 from '../Assets/Images/66.jpg'
import image67 from '../Assets/Images/67.jpg'
import image68 from '../Assets/Images/68.jpg'
import image69 from '../Assets/Images/69.jpg'
import image70 from '../Assets/Images/70.jpg'
import image71 from '../Assets/Images/71.jpg'
import image72 from '../Assets/Images/72.jpg'
import image73 from '../Assets/Images/73.jpg'
import image74 from '../Assets/Images/74.jpg'
import image75 from '../Assets/Images/75.jpg'
import image76 from '../Assets/Images/76.jpg'
import image77 from '../Assets/Images/77.jpg'
import image78 from '../Assets/Images/78.jpg'
import image79 from '../Assets/Images/79.jpg'
import image80 from '../Assets/Images/80.jpg'
import image81 from '../Assets/Images/81.jpg'
import image82 from '../Assets/Images/82.jpg'
import image83 from '../Assets/Images/83.jpg'
import image84 from '../Assets/Images/84.jpg'
import image85 from '../Assets/Images/85.jpg'
import image86 from '../Assets/Images/86.jpg'
import image87 from '../Assets/Images/87.jpg'
import image88 from '../Assets/Images/88.jpg'
import image89 from '../Assets/Images/89.jpg'
import image90 from '../Assets/Images/90.jpg'
import image91 from '../Assets/Images/91.jpg'
import image92 from '../Assets/Images/92.jpg'
import image93 from '../Assets/Images/93.jpg'
import image94 from '../Assets/Images/94.jpg'
import image95 from '../Assets/Images/95.jpg'
import image96 from '../Assets/Images/96.jpg'
import image97 from '../Assets/Images/97.jpg'
import image98 from '../Assets/Images/98.jpg'
import image99 from '../Assets/Images/99.jpg'
import image100 from '../Assets/Images/100.jpg'
import image101 from '../Assets/Images/101.jpg'
import image102 from '../Assets/Images/102.jpg'
import image103 from '../Assets/Images/103.jpg'
import image104 from '../Assets/Images/104.jpg'
import image105 from '../Assets/Images/105.jpg'
import image106 from '../Assets/Images/106.jpg'
import image107 from '../Assets/Images/107.jpg'
import image108 from '../Assets/Images/108.jpg'
import image109 from '../Assets/Images/109.jpg'
import image110 from '../Assets/Images/110.jpg'
import image111 from '../Assets/Images/111.jpg'
import image112 from '../Assets/Images/112.jpg'
import image113 from '../Assets/Images/113.jpg'
import image114 from '../Assets/Images/114.jpg'
import image115 from '../Assets/Images/115.jpg'
import image116 from '../Assets/Images/116.jpg'
import image117 from '../Assets/Images/117.jpg'
import image118 from '../Assets/Images/118.jpg'
import image119 from '../Assets/Images/119.jpg'
import image120 from '../Assets/Images/120.jpg'
import image121 from '../Assets/Images/121.jpg'
import image122 from '../Assets/Images/122.jpg'

import wedding from '../Assets/Home/Wedding.jpg'
import rituals from '../Assets/Home/ritual 3.jpg'
import cocktail from '../Assets/Home/cocktail.jpg'
import mehndi from '../Assets/Home/Mehendi.jpg'
import sangeet from '../Assets/Home/sangeet.jpg'
import destination from '../Assets/Home/destination.jpg'
import reception from '../Assets/Home/reception2.jpg'
import sports from '../Assets/Images/sports.JPG'
import doctor from '../Assets/Images/doctor.JPG'
import birthday from '../Assets/Images/birthday.JPG'
import ring from '../Assets/Images/ring.jpg'
import proposal from '../Assets/Images/proposal.jpg'
import anniversary from '../Assets/Images/anniversary.jpg'
import babyshower from '../Assets/Images/babyshower.jpg'
import productlaunch from '../Assets/Images/productlaunch.jpeg'
import tradeshow from '../Assets/Images/tradeshow.jpeg'
import agm from '../Assets/Images/agm.jpeg'
import college from '../Assets/Images/college.jpg'
import office from '../Assets/Images/office.jpg'
import mall from '../Assets/Images/mall.jpg'
import exhibit from '../Assets/Images/exhibit.jpg'
import house from '../Assets/Images/house.jpg'
import etr from '../Assets/Images/etr.jpg'
import conference from '../Assets/Images/conference.jpg'

import sample from '../Assets/Home/reception.jpg'

export const weddings = {
    caption: 'Weddings',
    title: '',
    captionTwo: ''
}

export const dragCard = {
    one: {
        title: 'Venue Planning',
        content: 'Fancy having a beach wedding in Goa? palace wedding in Udaipur?? Want to tie a knot at best hotel in Ahmedabad We get you varieties of venues in India & abroad. '
    }
    ,
    two: {
        title: 'Designing',
        content: 'This team will design various items like: Logo, Welcome letter, Itinerary, Thank you letter, Luggage tags, Key Jacket etc as per the requirements. '
    },
    
    three: {
        title: 'Production',
        content: 'This team will be taking care of the décor part of the event and will be working towards getting the best work delivered from the decorator. '
    }

    ,

    four: {
        title: 'Hospitality',
        content: ' Smooth check-in of guests at the hotel. Taking RSVP & collecting their id cards in advance. Placing the room hampers & catering to the needs of the guests.  '
    }
    ,

    five: {
        title: 'Logistics',
        content: 'The logistics team will work towards commuting the guests to the hotel from airport/station and function venue.'
    }

    ,

    six: {
        title: 'Artist Management',
        content: ' Managing the artists requirements along with backstage management, sound, light & LED requirements as per the design decided. '
    }
    ,
    seven: {
        title: 'Food and Beverages',
        content: 'Supervising, getting the food & beverages ready in time and as per menu decided for the functions. '
    }
    ,

    eight: {
        title: 'Agency Handling',
        content: ' We will be co-ordinating with various agencies like Photographer & Videographer, Maharaj, Make Up Artists, Mehendi Artists etc. '
    }
    ,

    nine: {
        title: 'Transparent Dealings',
        content: 'Our team while planning the wedding keeps the client in every loop be it with the vendor or any other agency which makes us unique and easy to work with. Transparency helps create the bond and trust between the client and our team.'
    }
}

export const featureContent = {
    one: {
        title: 'Expertise',
        content: "With more than a decade into the business of wedding event planning, we have enormous experience with the industry & understand the nuances of the event.We have developed the expertise to understand the client’s emotional connect to their wedding event and meet their expectations with personalized consulting."
    }
    ,
    two: {
        title: 'Detail Oriented',
        content: "Our team is a bunch of great listeners and we give attention to the smallest and minutest details which allows our team to conceptualize your dream wedding. We create a detailed paperwork to deliver the best work making sure that nothing is missed in making the client’s wedding a memorable one."
    }

    ,
    three: {
        title: 'Value for Money',
        content: "The budget for the wedding plays an important role in conceptualising the wedding, however we believe that the emotional aspect remains the same irrespective of the budget. Whatever Budget given by our client, our team works to give nothing but a 100% in that, and we strive for even more."
    }

    ,
    four: {
        title: 'Transparent Dealings',
        content: "Our team while planning the wedding keeps the client in every loop be it with the vendor or any other agency which makes us unique and easy to work with. Transparency helps create the bond and trust between the client and our team."
    },

}

export const aboutContent = {
    home: {
        paraOne: 'With an experience of hosting a lot of weddings in Ahmedabad, Goa, Udaipur, Mussoorie, Lonavala, Kerala and many other famous destinations, we have always believed in crafting an astounding wedding of your choice – extravagant or intimate, royal or traditional, you think of it and we make it happen. We are obsessed with creating off-beat, fun & contemporary weddings.',
        paraTwo: 'Just watch us weave every strand of your dreamy day into a grand magical carpet. Creating memorable moments by blending Indian tastes and traditions with contemporary touches has always been our core focus. This permits bride, groom and both families to completely enjoy each moment of the wedding celebrations staying completely stress-free.'
    },

    about: {
        paraOne: 'We at MadWorld India were founded in the year of 2010 with the aim of making event management a hassle-free and smooth process. In an industry with a lot of players, we aim to craft each and every event with out-of-the-box ideas and deliver invigorating results. Ideating and managing more than 200 luxury weddings in the last 8 years and more than 600 corporate events in the last 11 years are proof of our quality and impeccable services. Our team of professionals have management education and 5+ years of experience working at senior positions in leading entertainment companies. With this, you can trust and confide in our ability to flawlessly execute each and every event of yours. We understand the fine balance of curating events with the perfect amount of creativity for a refreshing experience while also keeping the individuality and originality of your ideas at the forefront which results in an overall a never to be forgotten event.', 
        paraTwo: 'We are driven and dedicated to assisting you in crafting and organizing key events and innovative conveyance of gatherings for an assortment of corporate and business events, including, innovation, pharmaceutical, non-benefit, sports, etc. Furthermore, also makes all your requirements come alive for a variety of social events such as birthday parties, wedding anniversaries, proposals, and ring ceremonies to design each and every detail that aligns with your visions and ideas for a dream wedding. We are a luxury event management company in Ahemdabad that aspires to assist you in making all your imagination come to life. In a mission to set the bar higher with our dedicated team, unique and never-before ideas, and impeccable execution we are at your constant service for each and every event.'
    }
}

export const ipc = {
    social: {
        contentOne: "Every social event is filled with fun, love, and laughs. Guests come to have a good time together in a venue with warm and welcoming decor, a light atmosphere, good music, and a hearty menu. It's an occasion where we leave aside our worries, work, and deadlines for a few hours to enjoy quality time with family and friends. What would you rather do? fixate over the little details of your event or enjoy the moments of today that will become your most cherished memories for the rest of your life.",
        contentTwo: "At your service, we are an event management company in Ahmedabad to plan any of your social events like birthday parties, anniversaries, proposals, housewarmings, baby showers, retirement, etc. From candles to gifts and starters to desserts, sit back and relax because Our team got it all covered.",

        rowOneTitle: 'Anniversary',
        rowOneContent: 'Our team of event management experts is here to help you celebrate your love story and every milestone with 100% dedication and commitment. We are at your service to design every fine detail of your wedding anniversary.',
        rowOneImage: anniversary,

        rowTwoTitle: 'Birthday',
        rowTwoContent: '“Our age is merely the number of years the world has been enjoying us!”. With every day there is new learning and it is important to memorialize every one of it. We are here to organize an amazing birthday party for you or a surprise party for your loved one that covers all aspects from location, music , food, games, and gifts.',
        rowTwoImage: birthday,

        rowThreeTitle: 'Ring Ceremony',
        rowThreeContent: '“The promise of togetherness for the lifetime starts with a ring”. The holy day of coming together of your families, generations, values, rituals, and tradition is an important marker in your wedding journey and we are here to uniquely and creatively craft as per your choices.',
        rowThreeImage: ring,

        rowFourTitle: 'Proposal',
        rowFourContent: 'Your words of love and promise with our arrangements of red roses, scenic blue skies, romantic setting and decor, light music, and favorite dishes in the menu are the perfect ingredients for your surprise proposal. Our teams are managers equipped to make your every wish come alive.',
        rowFourImage: proposal,

        rowFiveTitle: 'Baby Shower',
        rowFiveContent: 'The roller coaster ride that is filled with a lifetime of love, laughter, and surprises begins with the birth of your baby. From a perfect location to warm and unique décor to fun activities for your guests to personalized gifts for everyone we promise to make your sweet day a little more sweeter with our service.',
        rowFiveImage: babyshower,

        rowSixTitle: 'Housewarming',
        rowSixContent: 'Home is the collection of a thousand memories and countless moments of love and laughter with your friends and family that we dearly cherish. From setting up stalls and tents for food and refreshments to sending housewarming gifts, We at MadWorld are at your service to help you within a smooth and memorable transition of a house that becomes your home.',
        rowSixImage: house,


    },

    weddings: {
        contentOne: "Breaking the monotony of a conventional wedding, we bring a novel perspective to designing, planning, decorating, and servicing an incredible celebration called ‘Marriage’! A lot of coffee, chit-chats, planning, execution, and our client’s dreams have led to a glowing track record of incredible weddings.",
        contentTwo: "Starting from venue sourcing to picking and executing the right theme, personalizing every little detail, from hashtags to food and drinks menu, bridesmaids and grooms mates innovative invites, all your wedding stationery, dance scripts to dance moves, photography & videography, hospitality & guests management, special entry of the bride and groom, etc, we make your love story shines through and through. We are as much behind the scenes streamlining, coordinating and budgeting as much as we are in front by your side, hovering, jumping, managing, hustling and getting things done 24/7 for your special day. Our wedding planning service includes...."

        ,
        rowOneTitle: 'Pre-wedding Photoshoot',
        rowOneContent: 'Be yourself before entering into the world of rituals! Pre-wedding photoshoots are the trendiest thing in a wedding! While steppin into the world of togetherness, getting clicked in beautiful backdrops heartens th couples to interact in a romantic way A pre-wedding shoot is generally to bring out the romantic, creative, relaxed, an happy side of you as a couple. While the locations, styles, and approaches evolv over the years, raw storytelling adds a flavor to your intimate journey We have a team of photographers and videographers to capture the magica moments in the prettiest way possible. They would click pictures that speak t you, touch your heart and bring you oodles of happiness when two committe hearts started their blissful journey Talking about the location, don’t worry! Just pinpoint your requirements and w shall suggest from the plethora of gorgeous locations available with mesmerizin ideas! Whether you want the shoot at pristine beaches, or terrains, mountains heritage buildings, Forts, Royal Palaces, Luxurious resorts, chaotic streets for a off-beat feel and many more places, just leave this daunting work to the team o photographers and videographers, and see wonders happen Let them capture the range of emotions that are going to be very special for you entire life!',
        rowOneImg: sample,

        rowTwoTitle: 'Rituals',
        rowTwoContent: 'Weddings are a celebration of love and commitment whereas rituals ar celebration of the family’s tradition and culture! Indian wedding traditions  customs are diver These wedding rituals symbolize unity and make wedding ceremonies m poignant! Weddings are held in a unique manner in every religion and state. Be in the north, south, east, or west, each ritual is celebrated with great gusto  ze Every family has a different set of rituals. Whether it’s Haldi, Mata Ki Chowki, G Shanti, Mameru, Maandvo, or any other traditions as per your community culture, we shall make the perfect arrangements for your special day as per your requirement. We ensure to take care of every small detail that includes  archaic traditions while keeping it at a simple and humble note.',
        rowTwoImg: rituals,
        
        rowThreeTitle: 'Mehndi',
        rowThreeContent: 'Mehndi! One such hap of a ceremony filled with vibrant colors, flowers, dancing, paper crafts, photo props, etc. It’s the most joyous and vibrant celebration of a bride’s wedding. It’s a function where a bride can experiment with different prints and styles of outfits, and quirky accessories and make the world go gaga about it. Mehndi is a boost-up factor of the wedding, a fun element with upbeat dhols and music to groove around. This day is definitely out of the ordinary day for the bride. We can design the fanciest, elementary, and a fun-loving décor to set the tone of magic. The place can be set up with fresh flowers, fairy lights, and trendy and chic photo props to add loads of drama to your festivities. It can create a whole new vibe of the place and décor. From paper flowers to boats to paper fans etc, there is no limit to what you can make by Origami. Hang them by a thread and let everybody go wow and feel the perfect colorful vibes of the beautiful décor! And lastly, we can help you make a bang couple entry to grab everyone’s attention.',
        rowThreeImg: mehndi,
        
        rowFourTitle: 'Sangeet',
        rowFourContent: 'The sangeet has its own charm in wedding soirees. It’s one of a glam affair wit lot of fun activities, joy, excitement, and grandeur with family and frien Everything glitzy is meant for sangeet night. It is hosted to get everyone drenc in a celebratory mo Sangeet nights are incomplete without glimmering and glittering decors t encapsulate everyone’s heart and eyesight at first glance. Right from a Pag setup or a Bollywood night set up, LED backdrops, attractive stage setup with drapes or floral arch or vintage tables or lanterns, etc, add a glamorous bash the chilled-out and warm vibes of the eveni We can help you arrange a Celebrity Night, Garba Performances, a fam choreography, jazzy performances, and lots of dancing for the night-long madn of unstoppable fe Just gear up, be the highlight of the evening in a glitzy décor and leave all  hassles on us!',
        rowFourImg: sangeet,

        rowFiveTitle: 'Cocktail party',
        rowFiveContent: 'Weddings filled with just love, dance, and hearty laughs feel a little incomplete without a Cocktail Party! Weddings these days are not limited to rituals and ceremonies anymore! A cocktail party is where people are looking for more fun and dancing amidst the traditional rituals. They are becoming an essential function of Indian weddings these days. A top-notch party at the destination wedding with food, drinks, and dancing all night, can create a great atmosphere at a wedding. A Wedding Cocktail soiree is a perfect way to relax, let lose yourself, know each other, dance to groovy numbers and party with your friends and family. They can be either before your wedding as a welcome party or as a traditional dinner at your reception as a post-wedding ceremony gathering. We at Madworld can plan a signature cocktail party or one that sets around your theme. We can show you the venues as per your taste and have it as a low-key or over-the-top as you wish. Just dress up in your best attire, celebrate the happy union by combining fun, socializing, and mingling, and leave the creativity entirely on us!',
        rowFiveImg: cocktail,

        rowSixTitle: 'Wedding',
        rowSixContent: 'Weddings are a profound mix of cultural elements, colorful celebrations, festivities, rituals, and many more fun things. We understand what you want as a couple and as a family. We try to understand what celebrates you and we do everything exactly the way you want on your big day. Madworld designs and conceptualizes each wedding adding a personal touch to the expectation of the family and the couple. We deliver a creatively and emotionally fulfilling wedding experience, promising to make your wedding a splendidly plush affair. This dreamy day will be filled with any kind of props or décors like contemporary décor of colorful blooms, floral set up, velvety props, drape base décor, fairy lights, Glass candles, colorful seating areas, pretty corner settings, beautiful backdrops or theme-based décor, as per your requirement and within your budget. We are sure the opulence of the wedding will hold everyone at their first glance. So what are you waiting for? Have a happy, joyous, and vibrant celebration of your love in a royal way with Madworld!',
        rowSiximg: wedding,

        rowSevenTitle: 'Destination wedding',
        rowSevenContent: 'If you are thinking of a heavenly destination to tie a knot with your soulmate, t you have landed at the right place! If you are looking for an exotic or remote historic location, we can suggest our few best-handpicked locations which  tried and test A destination wedding is where you can picture yourselves in a warm, luxuri atmosphere with a close group of happy friends and family. We at Madworld w help make your dreams of the perfect destination wedding a reality with all  options that would fit your budget and requirement. A destination wedding can be as cool, casual or extravagant, and flamboyant as you like at a romantic historic, or international place of your choi You can choose from a variety of domestic and international locations we h hosted weddings till dates like Goa, Udaipur, Jaipur, Kerala, Lonavala, Krabi, Turk Bali, Phuket, Dubai, Thailand, and many more. We ensure, that all the deta worked best for you right from the venue to the menu at an affordable cost.',
        rowSevenImg: destination,

        rowEightTitle: 'Reception',
        rowEightContent: 'A major episode post weddings are the Reception Ceremony! Being the last bash of the wedding celebrations and being the first public appearance of the newlywed couple post marriage, host a glam reception party for your guests. It’s a time for family and friends to rejoice and welcome a new member of the family. We at Madworld will suggest you some smashing entry of the bride and the groom with the stage decked up with beautiful drapes or floral backdrop and some vintage sofa seating. We shall make it a grand soiree for you with the right kind of panache and elegance! It is further obvious to say how a glitzy decor can lend oodles of aesthetical charm to your photographs.',
        rowEightImg: reception
    },

    corporate: {
        contentOne: "Large corporate events are fast-paced with strict timelines and cross-functional teams. Annual conferences and meetings give your brand the perfect opportunity to showcase new products and services to stakeholders and then reward them for their hard work and commitment. We at MadWorld India as a corporate event management firm in Ahmedabad , Gujarat. We oversee all aspects of event planning and management including working at a variety of locations, negotiating the best contracts for different vendors servicing at the event, and having backup solutions for any issue that may arise on the event day. ",
        contentTwo: "We also help you with follow-up activities like sending gifts or souvenirs to guests all the while adhering to the event budget. We at MadWorld India are here to ensure you are not just creating a moment but a movement. As professional event management services, we recognize the importance of all parts of the event being played out smoothly displaying professionalism that maintains your brand’s value and events that exhibit your brand’s promises to its stakeholders.",

        rowOneTitle: 'Office inauguration',
        rowOneContent: 'From making guests’ lists, and finalizing dates to planning out a sequence of different activities for the event day, strategically and creatively using your company’s logo to handing out gifts and product samples to guests, we are at your service to plan and execute a memorable office inauguration.',
        rowOneImg: office,

        rowTwoTitle: 'Product launch',
        rowTwoContent: 'Deciding out the dates and locations, creating the anticipation around your product and launch day, strategic invite list, and finally actual action of your product launch itself. It is a day to launch your product and the efforts of your organization into the marketplace. MadWorld India promises you an event to make people go talk about your product both on social media and in person.',
        rowTwoImg: productlaunch,

        rowThreeTitle: 'Mall activity',
        rowThreeContent: 'the hustle and bustle, bright lights, curious pairs of eyes, the chit chats, and laughter here & there of the mall are a perfect place for your promotional event planned and executed from beginning to end by our team of creative professionals.',
        rowThreeImg: mall,

        rowFourTitle: 'Exhibition',
        rowFourContent: 'Creating various sizes of stalls from the ground up, proper arrangement of drinking water, food, and beverages for all the visitors, diverse companies and their products on display, set up for any emergency or issue. Our team specializes in planning out all these fine details so that you witness a successful exhibition.',
        rowFourImg: exhibit,

        rowFiveTitle: 'Tradeshow',
        rowFiveContent: 'When all the players from the industry come into one place to discuss, display and demonstrate their products and what they wish to sell to visitors all the preparations from your end needs to be perfect from designing their booth to necessary installations and other logistics. Our team of detail-oriented professionals is here to take care of everything for your tradeshow.',
        rowFiveImg: tradeshow,

        rowSixTitle: 'Conferences',
        rowSixContent: 'It is your day to connect face to face with your audience while networking with people of note and contacts that matter to your organization, we understand that any hiccups are the last thing you want on your mind. We aim to free you of these hassles and curate a unique and impactful experience for your attendees. Our team at MadWorld and its expertise are at your constant service.',
        rowSixImg: conference,

        rowSevenTitle: 'AGM',
        rowSevenContent: 'Yearly gathering of the organization and all its stakeholders to discuss business matters requires that every aspect of the event is in consideration and is planned accordingly. From finalizing meeting location, managing RSVPs , handing out meeting agendas, facilities of refreshments, and other logistics we help your organization arrange a fruitful Annual General Meeting event.',
        rowSevenImg: agm,

        rowEightTitle: 'Employee Team retreat',
        rowEightContent: 'Employee team building - "Alone we do so little, together we do so much" /"Talent wins games but teamwork and intelligence win championships". We got in our store a variety of fun and engaging events from our team to yours.',
        rowEightImg: etr,

        rowNineTitle: 'Doctor conferences',
        rowNineContent: 'For our front-line workers, we understand the impact and essentiality of your profession. These conferences help keep the industry updated on recent trends and developments which further contributes to society as a whole. Such an important event should be a hurdle free and that is our main objective. From logistics to lighting to each and every basic technicality we are at your beck and call.',
        rowNineImg: doctor,

        rowTenTitle: 'Sports events',
        rowTenContent: 'For every hidden Sania Mirza, Sachin Tendulkar, Vishwanathan Anand, Mithali Raj , PV Sindhu sports events are the perfect place that brings out talented individuals of the future. We help you execute sports events for people of all ages.',
        rowTenImg: sports,

        rowElevenTitle: 'College Events',
        rowElevenContent: 'education in more than just textbooks and classes. It is the people you meet and the opportunities you are provided. College fests and other such events are major contributors to student life. We make sure you are covered from every side, music, food, activities, whatever you need!',
        rowElevenImg: college,
        
    }
}

export const galleryImages = [
    { id: '1', src: image1, imageName: 'image1', tag: 'sangeet', thumbnailWidth: 320,thumbnailHeight: 174, },
    { id: '2', src: image2,  imageName: 'image2', tag: 'sangeet',  thumbnailWidth: 320,
    thumbnailHeight: 174},
    { id: '3', src: image3, imageName: 'image3', tag: 'sangeet',  thumbnailWidth: 320,
    thumbnailHeight: 174  },
    { id: '4', src: image4, imageName: 'image4', tag: 'mehndi',  thumbnailWidth: 320,
    thumbnailHeight: 174  },
    { id: '5', src: image5 ,  imageName: 'image5', tag: 'mehndi', thumbnailWidth: 320,
    thumbnailHeight: 174 },
    { id: '6', imageName: 'image6', tag: 'mehndi', src: image6, thumbnailWidth: 320,
    thumbnailHeight: 174  },
    { id: '7', imageName: 'image7', tag: 'reception', src: image7 , thumbnailWidth: 320,
    thumbnailHeight: 174 },
    { id: '8', imageName: 'image8', tag: 'reception', src: image8 , thumbnailWidth: 320,
    thumbnailHeight: 174 },
    { id: '9', imageName: 'image9', tag: 'reception', src: image9 , thumbnailWidth: 320,
    thumbnailHeight: 174 },
    { id: '10', imageName: 'image10', tag: 'sangeet', src: image10 , thumbnailWidth: 320,
    thumbnailHeight: 174 },
    { id: '11', imageName: 'image11', tag: 'sangeet', src: image11  },
    { id: '12', imageName: 'image12', tag: 'sangeet', src: image12  },
    { id: '13', imageName: 'image13', tag: 'wedding', src: image13  },
    { id: '14', imageName: 'image14', tag: 'wedding', src: image14  },
    { id: '15', imageName: 'image15', tag: 'wedding', src: image15  },
    { id: '16', imageName: 'image16', tag: ' ', src: image16  },
    { id: '17', imageName: 'image17', tag: ' ', src: image17  },
    { id: '18', imageName: 'image18', tag: ' ', src: image18  },

    { id: '19', imageName: 'image19', tag: 'reception', src: image19  },
    { id: '20', imageName: 'image20', tag: 'reception', src: image20  },
    { id: '21', imageName: 'image21', tag: 'reception', src: image21  },

    { id: '22', imageName: 'image22', tag: 'mehndi', src: image22  },
    { id: '23', imageName: 'image23', tag: 'mehndi', src: image23  },
    { id: '24', imageName: 'image24', tag: 'mehndi', src: image24  },
    { id: '25', imageName: 'image25', tag: 'mehndi', src: image25  },
    { id: '26', imageName: 'image26', tag: 'mehndi', src: image26  },
    { id: '27', imageName: 'image27', tag: 'mehndi', src: image27  },
    { id: '28', imageName: 'image28', tag: 'wedding', src: image28  },
    { id: '29', imageName: 'image29', tag: 'wedding', src: image29  },
    { id: '30', imageName: 'image30', tag: 'wedding', src: image30  },

    { id: '31', imageName: 'image31', tag: 'wedding', src: image31  },
    { id: '32', imageName: 'image32', tag: 'reception', src: image32  },
    { id: '33', imageName: 'image33', tag: 'reception', src: image33  },

    { id: '34', imageName: 'image34', tag: 'wedding', src: image34  },
    { id: '35', imageName: 'image35', tag: 'reception', src: image35  },
    { id: '36', imageName: 'image36', tag: 'reception', src: image36  },

    { id: '37', imageName: 'image37', tag: 'sangeet', src: image37  },
    { id: '38', imageName: 'image38', tag: 'sangeet', src: image38  },
    { id: '39', imageName: 'image39', tag: 'wedding', src: image39  },

    { id: '40', imageName: 'image40', tag: 'wedding', src: image40  },
    { id: '41', imageName: 'image41', tag: 'wedding', src: image41  },
    { id: '42', imageName: 'image42', tag: 'wedding', src: image42  },

    { id: '43', imageName: 'image43', tag: 'wedding', src: image43  },
    { id: '44', imageName: 'image44', tag: 'wedding', src: image44  },
    { id: '45', imageName: 'image45', tag: 'wedding', src: image45  },
    
    { id: '46', imageName: 'image46', tag: 'mehndi', src: image46  },
{ id: '47', imageName: 'image47', tag: 'cocktail', src: image47  },
{ id: '48', imageName: 'image48', tag: 'cocktail', src: image48  },
{ id: '49', imageName: 'image49', tag: 'cocktail', src: image49  },
{ id: '50', imageName: 'image50', tag: 'destination weddings', src: image50  },
{ id: '51', imageName: 'image51', tag: 'destination weddings', src: image51  },
{ id: '52', imageName: 'image52', tag: 'destination weddings', src: image52  },
{ id: '53', imageName: 'image53', tag: 'destination weddings', src: image53  },
{ id: '54', imageName: 'image54', tag: 'destination weddings', src: image54  },
{ id: '55', imageName: 'image55', tag: 'destination weddings', src: image55  },
{ id: '56', imageName: 'image56', tag: 'destination weddings', src: image56  },
{ id: '57', imageName: 'image57', tag: 'wedding', src: image57  },
{ id: '58', imageName: 'image58', tag: 'wedding', src: image58  },
{ id: '59', imageName: 'image59', tag: 'wedding', src: image59  },
{ id: '60', imageName: 'image60', tag: 'wedding', src: image60  },
{ id: '61', imageName: 'image61', tag: 'wedding', src: image61  },
{ id: '62', imageName: 'image62', tag: 'wedding', src: image62  },
{ id: '63', imageName: 'image63', tag: 'wedding', src: image63  },
{ id: '64', imageName: 'image64', tag: 'sangeet', src: image64  },
{ id: '65', imageName: 'image65', tag: 'sangeet', src: image65  },
{ id: '66', imageName: 'image66', tag: 'reception', src: image66  },
{ id: '67', imageName: 'image67', tag: 'reception', src: image67  },
{ id: '68', imageName: 'image68', tag: 'reception', src: image68  },
{ id: '69', imageName: 'image69', tag: 'mehndi', src: image69  },
{ id: '70', imageName: 'image70', tag: 'mehndi', src: image70  },
{ id: '71', imageName: 'image71', tag: 'mehndi', src: image71  },
{ id: '72', imageName: 'image72', tag: 'rituals', src: image72  },
{ id: '73', imageName: 'image73', tag: 'rituals', src: image73  },
{ id: '74', imageName: 'image74', tag: 'rituals', src: image74  },
{ id: '75', imageName: 'image75', tag: 'mehndi', src: image75  },
{ id: '76', imageName: 'image76', tag: 'mehndi', src: image76  },
{ id: '77', imageName: 'image77', tag: 'mehndi', src: image77  },
{ id: '78', imageName: 'image78', tag: 'mehndi', src: image78  },
{ id: '79', imageName: 'image79', tag: 'all', src: image79  },
{ id: '80', imageName: 'image80', tag: 'mehndi', src: image80  },
{ id: '81', imageName: 'image81', tag: 'mehndi', src: image81  },
{ id: '82', imageName: 'image82', tag: 'mehndi', src: image82  },
{ id: '83', imageName: 'image83', tag: 'wedding', src: image83  },
{ id: '84', imageName: 'image84', tag: 'sangeet', src: image84  },
{ id: '85', imageName: 'image85', tag: 'reception', src: image85  },
{ id: '86', imageName: 'image86', tag: 'reception', src: image86  },
{ id: '87', imageName: 'image87', tag: 'destination weddings', src: image87  },
{ id: '88', imageName: 'image88', tag: 'destination weddings', src: image88  },
{ id: '89', imageName: 'image89', tag: 'mehndi', src: image89  },
{ id: '90', imageName: 'image90', tag: 'mehndi', src: image90  },
{ id: '91', imageName: 'image90', tag: 'mehndi', src: image91  },
{ id: '92', imageName: 'image90', tag: 'mehndi', src: image92  },
{ id: '93', imageName: 'image90', tag: 'mehndi', src: image93  },
{ id: '94', imageName: 'image90', tag: 'mehndi', src: image94  },
{ id: '95', imageName: 'image90', tag: 'mehndi', src: image95  },
{ id: '96', imageName: 'image90', tag: 'mehndi', src: image96  },
{ id: '97', imageName: 'image90', tag: 'all', src: image97  },
{ id: '98', imageName: 'image90', tag: 'mehndi', src: image98  },
{ id: '99', imageName: 'image90', tag: 'mehndi', src: image99  },
{ id: '100', imageName: 'image100', tag: 'wedding', src: image100  },
{ id: '101', imageName: 'image101', tag: 'mehndi', src: image101  },
{ id: '102', imageName: 'image102', tag: 'sangeet', src: image102  },
{ id: '103', imageName: 'image103', tag: 'cocktail', src: image103  },
{ id: '104', imageName: 'image104', tag: 'wedding', src: image104  },
{ id: '105', imageName: 'image105', tag: 'mehndi', src: image105  },
{ id: '106', imageName: 'image106', tag: 'sangeet', src: image106  },
{ id: '107', imageName: 'image107', tag: 'cocktail', src: image107  },
{ id: '108', imageName: 'image108', tag: 'reception', src: image108  },
{ id: '109', imageName: 'image109', tag: 'wedding', src: image109  },
{ id: '110', imageName: 'image110', tag: 'rituals', src: image110  },
{ id: '111', imageName: 'image111', tag: 'mehndi', src: image111  },
{ id: '112', imageName: 'image112', tag: 'sangeet', src: image112  },
{ id: '113', imageName: 'image113', tag: 'cocktail', src: image113  },
{ id: '114', imageName: 'image114', tag: 'reception', src: image114  },
{ id: '115', imageName: 'image115', tag: 'wedding', src: image115  },
{ id: '116', imageName: 'image116', tag: 'rituals', src: image116  },
{ id: '117', imageName: 'image117', tag: 'mehndi', src: image117  },
{ id: '118', imageName: 'image118', tag: 'sangeet', src: image118  },
{ id: '119', imageName: 'image119', tag: 'cocktail', src: image119  },
{ id: '120', imageName: 'image120', tag: 'reception', src: image120  },
{ id: '121', imageName: 'image121', tag: 'destination weddings', src: image121  },
{ id: '122', imageName: 'image122', tag: 'wedding', src: image122  },

    
]